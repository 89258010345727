import Head from "next/head";

import { SITE_URL } from "_config/index";

import { converToVideoObjectTime, removeWhiteSpaces } from "./utils";
import { getSEOContent, getSEOTitle }                 from "./utils/seo";

const CommonTags = () => {
	return (
		<Head>
			<meta content = "Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET, DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
				name = "SearchEngines"
			/>

			<meta content = "INDEX, FOLLOW"
				name = "Robots"
			/>

			{/* Removed by Sumit */}
			{/* <meta content = { `Rooter, ${ SITE_URL }` }
				name = "Author"
			/> */}

			<meta content = "INDEX,ALL"
				name = "Robots"
			/>

			<meta content = "INDEX, FOLLOW"
				name = "YahooSeeker"
			/>

			<meta content = "INDEX, FOLLOW"
				name = "msnbot"
			/>

			<meta content = "Default, follow"
				name = "googlebot"
			/>

			{/* Removed by Sumit */}
			{/* <meta content = { SITE_URL }
				name = "owner"
			/> */}

			{/* Removed by Sumit */}
			{/* <meta content = "English"
				name = "language"
			/> */}

			{/* Removed by Sumit */}
			{/* <meta content = "2 days"
				name = "revisit-after"
			/> */}

			{/* <meta content = { `${ SITE_URL }/images/rooter-logo-white.webp` }
				property = "og:image"
			/> */}

			<meta content = "Rooter"
				property = "og:image:alt"
			/>

			<meta content = "website"
				property = "og:type"
			/>

			<meta
				content = "Rooter"
				property = "og:site_name"
			/>

			<meta content = "1758041771107136"
				property = "fb:page_id"
			/>

			<meta content = { `${ SITE_URL }/images/rooter-logo-white.webp` }
				name = "twitter:image:src"
			/>

			<meta content = "summary"
				name = "twitter:card"
			/>

			<meta content = "@RooterSports"
				name = "twitter:site"
			/>

			<meta content = "@RooterSports"
				name = "twitter:creator"
			/>

			<meta content = { SITE_URL }
				name = "twitter:domain"
			/>

			<meta content = "733010637492785152"
				property = "twitter:account_id"
			/>

		</Head>
	);
};

export const DefaultTags = props => {
	const { title, desc, endpoint } = props;

	return (
		<Head>
			<title>
				{title}
			</title>

			<meta content = { desc }
				name = "description"
			/>

			<link href = { `${ SITE_URL }${ endpoint }` }
				rel = "canonical"
			/>
		</Head>
	);
};

const OpenGraphTags = props => {
	const { title, desc, endpoint, thumbnail } = props;

	const image = thumbnail ? thumbnail : `${ SITE_URL }/images/rooter-logo-white.webp`;

	return (
		<Head>

			<meta content = { title }
				property = "og:title"
			/>

			<meta content = { image }
				property = "og:image"
			/>

			<meta content = { desc }
				property = "og:description"
			/>

			<meta content = { `${ SITE_URL }${ endpoint }` }
				property = "og:url"
			/>
		</Head>
	);
};

const TwitterTags = props => {
	const { title, desc } = props;

	return (
		<Head>
			<meta content = { title }
				name = "twitter:title"
			/>

			<meta content = { desc }
				name = "twitter:description"
			/>
		</Head>
	);
};

const AllTags = props => {
	const { seoTags = "", desc, endpoint, title, thumbnail } = props;

	const parsedSEOTags = seoTags.length ? JSON.parse ( JSON.parse ( seoTags )[0].metaData ) : "";

	const titleTag = seoTags.length ? JSON.parse ( JSON.parse ( seoTags )[1].titleData )[0].value : "";

	return (
		<>
			{seoTags.length > 0 ? (
				<>
					<Head>
						<title>
							{getSEOTitle ( titleTag, props )}
						</title>

						<link href = { `${ SITE_URL }${ endpoint }` }
							rel = "canonical"
						/>

					</Head>

					{parsedSEOTags.map ( ( tag, idx ) => {

						return (
							<Head key = { idx }>

								<meta content = { getSEOContent ( tag, props ) }
									{ ...( tag.name && { name: tag.name } ) }
									{ ...( tag.property && { property: tag.property } ) }
								/>
							</Head>
						);
					} )}
				</>
			)
				: (
					<>
						<DefaultTags
							desc = { desc }
							endpoint = { endpoint }
							title = { title }
						/>

						<OpenGraphTags
							desc = { desc }
							endpoint = { endpoint }
							thumbnail = { thumbnail }
							title = { title }
						/>

						<TwitterTags
							desc = { desc }
							title = { title }
						/>

						<CommonTags />
					</>
				)}
		</>
	);
};

export const LivePageTags = props => {
	const { seoTags, endpoint } = props;

	const title = "Rooter - You Can Watch Live Esports, Game Streaming Online";
	const desc  = "Watch live game streaming online with the best gaming streamers like Riyan Parag, Yuzvendra Chahal, Tanmay Bhatt, and Jonathan Gaming, and play top games like Free Fire, BGMI, and Valorant only on Rooter";

	return (
		<AllTags
			desc = { desc }
			endpoint = { endpoint }
			seoTags = { seoTags }
			title = { title }
		/>
	);
};

export const HomePageTags = props => {
	const { seoTags, endpoint } = props;

	const title = "Watch Free Fire Max, Pubg, Minecraft Game streaming & Esports on Rooter";
	const desc  = "Join India’s biggest Free Fire Max, BGMI, Pubg, Minecraft, Valorant, GTA V gaming streamers like Jonathan Gaming, Lokesh Gamer, Ghatak, Team Godlike, Team Orangutan, Neyoo, Clutchgod, ZGod, Scout, Mavi, Team S8UL, Pahadi Gaming, Total Gaming.";

	return (
		<AllTags
			desc = { desc }
			endpoint = { endpoint }
			seoTags = { seoTags }
			title = { title }
		/>
	);
};

export const ProfilePageTags = props => {
	const { userName, selectedGames, seoTags, endpoint } = props;

	const title = props?.title || `${ userName } Live Game Streams - Watch Now`;
	// const title = props?.title || `Watch ${ userName } ${ selectedGames } Live game streaming on Rooter`;
	const desc = props?.desc || `Follow ${ userName } on Rooter and enjoy the latest gaming streams. Watch your favorite games live on Rooter.`;

	return (
		<AllTags
			desc = { desc }
			endpoint = { endpoint }
			seoTags = { seoTags }
			title = { title }
		/>
	);
};

export const VideoPageTags = props => {
	const {
		videoTitle,
		userName,
		thumbnail,
		seoTags,
		endpoint,
		summary = "",
		createdAt,
		videoDuration,
		videoURL
	} = props;

	const title = `Watch ${ videoTitle } on Rooter by ${ userName }`;
	const desc  = `Watch ${ userName } latest gaming & esports videos on Rooter. Follow your favourite games Free Fire Max, BGMI, Pubg, Minecraft, Valorant, GTA V news and content.`;

	return (
		<>
			<AllTags
				desc = { desc }
				endpoint = { endpoint }
				seoTags = { seoTags }
				thumbnail = { thumbnail }
				title = { title }
			/>

			<VideoObjectSchema
				contentUrl = { videoURL }
				description = { summary }
				name = { videoTitle }
				thumbnail = { thumbnail }
				uploadDate = { createdAt }
				videoDuration = { videoDuration }
			/>
		</>
	);
};

export const StreamPageTags = props => {
	const { streamTitle, userName, thumbnail, seoTags, endpoint, description = "", startTimeUTC, streamURL } = props;

	const title = `Watch ${ userName } Live Stream - ${ streamTitle }`;
	const desc  = `Watch ${ userName } playing your favorite game. Don't miss out on live action!`;

	return (
		<>

			<AllTags
				desc = { desc }
				endpoint = { endpoint }
				seoTags = { seoTags }
				thumbnail = { thumbnail }
				title = { title }
			/>

			<VideoObjectSchema
				contentUrl = { streamURL }
				description = { removeWhiteSpaces ( description ) }
				name = { streamTitle }
				thumbnail = { thumbnail }
				uploadDate = { startTimeUTC || "" }
			/>
		</>
	);
};

const VideoObjectSchema = ( { name, description, thumbnail, uploadDate, videoDuration, contentUrl } ) => {

	const videoObject = `{
		"@context"     : "https://schema.org/",
		"@type"        : "VideoObject",
		"name"         : "${ name }",
		"description"  : "${ description }",
		"thumbnailUrl" : [ "${ thumbnail }" ],
		"uploadDate"   : "${ uploadDate }",
		"contentUrl"   : "${ contentUrl }"${ videoDuration ? "," : "" }
		${ videoDuration ?
		`"duration"    : "${ converToVideoObjectTime ( videoDuration ) }"`
		:
		""
}
	}`;

	return (
		<script
			dangerouslySetInnerHTML = { { __html: videoObject } }
			type = "application/ld+json"
		/>
	);
};

export const GamesPageTags = ( { endpoint } ) => {
	const title = "Watch Videos, Live Stream | Rooter";
	const desc  = "Join Rooter and watch live streams, videos and more!";

	return (
		<AllTags
			desc = { desc }
			endpoint = { endpoint }
			title = { title }
		/>
	);
};

export const SingleGamePageTags = props => {
	const { gameId, gameTitle, seoTags, endpoint } = props;

	const title = singleGamePageTagMapping[gameId]?.title || `Watch ${ gameTitle } Videos, Reels & Live Streams: Join the Fun!`;
	const desc  = singleGamePageTagMapping[gameId]?.desc || `Experience the best of ${ gameTitle } content! Watch epic gameplay and tutorials and stay updated with the latest news. Watch streams and videos from top creators like Lokesh Gamer, Jonty Gaming, Pahadi Gaming, Baseer and more.`;

	return (
		<AllTags
			desc = { desc }
			endpoint = { endpoint }
			seoTags = { seoTags }
			title = { title }
		/>
	);
};

const singleGamePageTagMapping = {
	2037096: {
		title : "BGMI Live Stream, Videos, Reels - Rooter",
		desc  : "Watch BGMI Live Stream on Rooter. Watch your favorite BGMI streamer playing BGMI live and win Rewards. Interact with other fans with live chat support."
	},
	5: {
		title : "Free Fire Live Stream, Videos, Reels - Rooter",
		desc  : "Watch Free Fire Live Stream on Rooter. Watch your favorite streamer playing free fire live and win Rewards. Interact with other fans with live chat support."
	},
	20161: {
		title : "Minecraft Live Stream, Videos, Reels - Rooter",
		desc  : "Watch Minecraft Live Streams, Videos, and Reels on Rooter. Watch your favourite streamers like Gamerfleet, RON9IE, DevXD, Bulky Star, Mc flame, Volt18 and others playing Minecraft."
	},
	1636599: {
		title : "Grand Theft Auto V Live Stream, Videos, Reels - Rooter",
		desc  : "Watch GTA 5 Live Stream on Rooter. Watch your favorite streamer playing GTA 5 and win Rewards. Interact with other fans with live chat support."
	},
	6: {
		title : "Watch Clash of Clans videos, reels, Live stream | Get COC new updates, gameplay & challenges",
		desc  : "Join the ultimate mobile gaming community of Clash of Clans. Build your base, train troops, and battle with other clans in epic wars. Watch and learn to upgrade your Town Hall, heroes, and defenses."
	},
	2036941: {
		title : "New State Mobile Live Stream, Videos, Reels - Rooter",
		desc  : "Watch New State Mobile Live Stream on Rooter. Watch your favorite streamer playing New State Mobile live and win Rewards. Interact with other fans with live chat support."
	},
	2: {
		title : "Call of Duty Mobile Live Stream, Videos, Reels - Rooter",
		desc  : "Watch Call of Duty Mobile Live Stream on Rooter. Watch your favorite streamer playing COD Mobile live and win Rewards. Interact with other fans with live chat support."
	}
};
