import dynamic                           from "next/dynamic";
import { Fragment, useEffect, useState } from "react";

import { useGetDeviceType }          from "_common/hooks/global";
import { HomePageTags }              from "_common/seo";
import { initialValidations }        from "_common/ssrWrapper";
import { enableBackupAPIsInCookies } from "_common/utils";
import {
	formatPersonalizedFeedData,
	mapBroadcastSessionProps
} from "_common/utils/broadcast";
import HomePage             from "_components/pages/home";
import {
	getCarouselSessions,
	getPopularStreamingGames
} from "_services/api/broadcast";
import {
	getPersonalizedFeedDataV2,
	getSEOTags,
	getShortVideos
} from "_services/api/feed";

const TournamentBanner   = dynamic ( () => import ( "_components/pages/home/tournamentBanner" ), { ssr: false } );
const ResponsiveCarousel = dynamic ( () => import ( "_components/templates/carousel" ) );
const AdSlot             = dynamic ( () => import ( "_components/features/ads/slot" ), { ssr: false } );

function Home ( props ) {
	const [ backuptabsWithFeedList, setBackuptabsWithFeedList ] = useState ( [] );

	const data    = props?.carousel || [];
	const seoTags = props?.seoTags;

	const tabsWithFeedList = backuptabsWithFeedList.length ? backuptabsWithFeedList : ( props?.tabsWithFeedList || [] );

	const getBackupData = async () => {
		try {
			const result = await getPersonalizedFeedDataV2 ( { pageNo: 1, pageSize: 10 } );

			const formattedTabsWithFeedList = formatTabsWithFeedList ( result );

			setBackuptabsWithFeedList ( formattedTabsWithFeedList );
		} catch ( error ) {
			// do nothing
		}
	};

	useEffect ( () => {
		if ( props?.tabsWithFeedList?.length )
			return;

		enableBackupAPIsInCookies ();
		getBackupData ();
	}, [] );

	const trendingFeedList  = tabsWithFeedList.filter ( item => parseInt ( item.feedTypeId ) === -1 )[0];
	const proFeedList       = tabsWithFeedList.filter ( item => item.feedTypeId.toString () === "693856" )[0];
	const remainingFeedList = tabsWithFeedList.filter ( item => {
		return parseInt ( item.feedTypeId ) !== -1 && item.feedTypeId.toString () !== "693856";
	} );

	const deviceType = useGetDeviceType ();
	const isMobile   = deviceType === "mobile";

	return (
		<Fragment>

			<HomePageTags
				endpoint = { props?.currentPath }
				seoTags = { seoTags }
			/>

			{
				data?.length ? (
					<ResponsiveCarousel data = { data } /> ) : null
			}

			{
				isMobile ? (
					<div style = { { display: "flex", justifyContent: "center" } }>
						<AdSlot
							id = { "gpt-passback-" }
							index = { "banner" }
							path = { "/22991961619/PG_MWEB_300x250" }
							size = { [ 300, 250 ] }
						/>
					</div>
				) : null
				// <AdBannerCarousel />
			}

			{
				!isMobile ?
					<TournamentBanner />
					: null
			}

			<HomePage
				allTabsWithFeedList = { isMobile ? tabsWithFeedList : ( remainingFeedList || [ [] ] ) }
				gameList = { props?.gameList || [] }
				isMobile = { isMobile }
				proFeedList = { proFeedList }
				reelList = { props.reelList }
				trendingFeedList = { trendingFeedList }
			/>

		</Fragment>
	);
}

const getServerSideProps = async context => initialValidations ( context, async defaultProps => {

	try {

		let seoTags, pageName = "homePage";

		const results = await Promise.allSettled (
			[
				getCarouselSessions.bind ( defaultProps ) ( { pageSize: 10 } ),
				getPersonalizedFeedDataV2.bind ( defaultProps ) ( { pageSize: 5, pageNo: 1 } ),
				getPopularStreamingGames.bind ( defaultProps ) ( { pageNo: 1, pageSize: 50, isDesktopGame: 0, sortByPriority: 1 } ),
				getSEOTags.bind ( defaultProps ) ( { pageName } ),
				getShortVideos.bind ( defaultProps ) ( { pageNo: 1, pageSize: 6 } )
			]
		);

		let sessions    = [], gameList = [], tabsWithFeedList = [], reelList = [];
		let forceLogout = false;

		results.forEach ( ( result, index ) => {
			if ( result.status === "fulfilled" ) {
				switch ( index ) {
					case 0:
						sessions = result.value;
						break;

					case 1:
						tabsWithFeedList = result.value;
						break;

					case 2:
						gameList = result.value;
						break;

					case 3:
						seoTags = result.value;
						break;

					case 4:
						reelList = formatPersonalizedFeedData ( result.value );

						break;

				}
			}

			if ( result.status === "rejected" && result.reason?.message === "TOKEN_EXPIRED_FORCE_LOGOUT" )
				forceLogout = true;
		} );

		if ( forceLogout )
			return {
				redirect: {
					destination : `/logout?forceLogout=${ true }&prevLocation=${ defaultProps?.currentPath }`,
					permanent   : false
				}
			};

		const games = formatGameList ( gameList );

		const formattedFeed = formatTabsWithFeedList ( tabsWithFeedList );

		const carousel = sessions.slice ( 0, 4 ).map ( session => {
			return { ...mapBroadcastSessionProps ( session ), session };
		} );
		const seo      = seoTags?.seo_tags ? seoTags.seo_tags : [];
		const reels    = reelList.slice ( 0, 6 );

		if ( !defaultProps?.isLoggedIn && carousel.length && games.length && formattedFeed.length && reels.length ) {
			context.res.setHeader (
				"Cache-Control",
				"public, s-maxage=90, stale-while-revalidate=120"
			);
		}

		return {
			props: {
				...defaultProps,
				carousel         : carousel,
				gameList         : games,
				seoTags          : seo,
				reelList         : reels,
				tabsWithFeedList : formattedFeed
			}
		};
	} catch ( error ) {
		return {
			props: {
				...defaultProps,
				carousel         : [],
				gameList         : [],
				seoTags          : [],
				reelList         : [],
				tabsWithFeedList : []
			}
		};
	}

}, { blockOBS: true } );

export { getServerSideProps };

export default Home;

const formatGameList = ( list = [] ) => {

	return list.map ( game => ( {
		image              : game?.image,
		liveStreamsCount   : game?.currentStreamCount,
		totalStreamsCount  : game?.totalStreams,
		name               : game?.name,
		id                 : game?.id,
		androidPackageName : game.androidPackageName,
		banner             : game.banner
		// appUrl: game.appUrl
	} ) );
};

const formatTabsWithFeedList = ( data = [] ) => {

	return data.map ( item => ( {
		display_name : item.tabName,
		feedList     : formatPersonalizedFeedData ( item.mixedFeedAndBroadcast ),
		feedTypeId   : item.tabId
	} ) );
};
